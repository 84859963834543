import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {},
    "index": {
        "color": "#000000",
        "font": "17px sans-serif"
    },
    "404/oldone": {},
    "publish": {},
    "contacts": {},
    "stonks9800": {},
    "mymaite": {},
    "unichrome": {},
    "ovoid": {},
    "policy": {}
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
