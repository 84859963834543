import React from "react";
import { useOverrides, Override, Menu } from "@quarkly/components";
import { Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-light"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0 0px 0",
			"text-align": "center",
			"font": "--headline2",
			"md-font": "--headline3",
			"text-transform": "uppercase",
			"children": <Link href="https://ternoxgames.com/" target="_self" color="#000000" text-decoration-line="initial">
				Ternoxgames.com
			</Link>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "0px 0",
			"font": "--headline3",
			"color": "#000000",
			"text-align": "center",
			"children": <Link
				href="mailto:me@ternoxgames.com"
				text-decoration="none"
				hover-text-decoration="underline"
				color="--darkL1"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				font="--lead"
			>
				me@ternoxgames.com
			</Link>
		}
	},
	"menu": {
		"kind": "Menu",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"font": "--base",
			"font-weight": "700",
			"md-flex-direction": "column",
			"md-align-items": "center"
		}
	},
	"menuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration": "none",
			"color": "--dark",
			"padding": "6px 12px"
		}
	},
	"menuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"color": "--primary"
		}
	},
	"menuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"padding": "6px"
		}
	},
	"menuOverride3": {
		"kind": "Override",
		"props": {
			"slot": "item-policy",
			"display": "none"
		}
	},
	"menuOverride4": {
		"kind": "Override",
		"props": {
			"slot": "item-mymaite",
			"display": "none"
		}
	},
	"menuOverride5": {
		"kind": "Override",
		"props": {
			"slot": "link-stonks9800"
		}
	},
	"menuOverride6": {
		"kind": "Override",
		"props": {
			"slot": "link-shiro",
			"display": "none"
		}
	}
};

const Menuu = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Menu {...override("menu")}>
			<Override {...override("menuOverride")} />
			<Override {...override("menuOverride1")} />
			<Override {...override("menuOverride2")} />
			<Override {...override("menuOverride3")} />
			<Override {...override("menuOverride4")} />
			<Override {...override("menuOverride5")} />
			<Override {...override("menuOverride6")} />
		</Menu>
		{children}
	</Section>;
};

Object.assign(Menuu, { ...Section,
	defaultProps,
	overrides
});
export default Menuu;